:root {
  --primary: #607D86;
  --secondary: #C44B4F;
  --light-gray: #CBCDCB;
  --dark-gray: #4C5355;
}

html,
body {
  height: 100%;
}

input[type=text],
input[type=text]:focus {
  //width: 50%;
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
  text-align: center;
}

.wrapper {
  height: 100%;
  width: 100%;
  margin: auto;
}

.divider {
  background-color: var(--light-gray);
  height: 1px;
  margin: 0;
  width: 99%;
}

.custom-btn {
  color: white;
  background-color: var(--primary) !important;
  width: 100%;
  text-align: center;
  line-height: 0%;
  white-space: nowrap;
}

.custom-btn[disabled] {
  opacity: .6;
}

.custom-btn[disabled]:hover {
  cursor: not-allowed;
}

.custom-btn:hover,
.custom-btn:focus {
  font-weight: bold;
}

.custom-btn:active {
  color: var(--primary);
}

.cancel-btn {
  color: white;
  text-align: center;
  line-height: 0%;
  background-color: var(--secondary) !important;
  border: 2px solid var(--secondary) !important;
}

.cancel-btn:hover,
.cancel-btn:focus {
  font-weight: bold;
}

.cancel-btn:active {
  background-color: white;
  color: var(--secondary);
}

.rounded {
  width: 50%;
  height: 25px;
  border: 2px solid var(--primary);
  border-radius: 25px;
  padding: 20px 10px;
}

.center {
  width: 100%;
  margin: auto;
  text-align: left;
  align-content: left;
  justify-content: left;
  padding-bottom: 12px;
}

.button-group-select {
  height: 44px;
  border: 2px solid var(--primary);
  padding: 0 10px;
  //border-left: 0;
}

.devider {
  background-color: var(--light-gray);
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 50%;
}

.description-text {
  color: var(--dark-gray);
  margin-top: 0px;
  margin-bottom: 0px;
}

.no-margin {
  padding-bottom: 0px;
}

.small-text {
  font-size: small;
}

.custom-file-label::after {
  background-color: #343a40 !important;
  color: white;
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space {
  width: 2px;
}

/* List */

.list {
  width: 100%;
  list-style-type: none;
  margin: 0 0 0 0;
  padding: 0;
}

.list li {
  cursor: pointer;
  height: 4em;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 0;
  box-sizing: border-box;
}

.list li:hover {
  background-color: #EEE;
  left: 0;
}

.list li.selected {
  background-color: #CFD8DC;
  color: white;
}

.list li.selected:hover {
  background-color: #BBD8DC;
  color: white;
}

.list .item {
  text-align: left;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.list .accent {
  vertical-align: middle;
  text-align: right;
  display: flex;
  align-items: center;
  font-weight: bold;
  min-width: 50px;
  padding-right: 12px;
}

.header {
  width: 100%;
  list-style-type: none;
  color: var(--light-gray);
  padding: 0;
  margin: 0;
}

.header li {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.long {
  height: 100%;
  width: 40%;
}

.normal {
  height: 100%;
  width: 20%;
}

.short {
  height: 100%;
  width: 12%;
}

a:hover {
  cursor: pointer;
}

.add-btn {
  width: 50px;
  text-align: center;
  justify-content: center;
}

.testresult-indicator {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.testresult-indicator.open {
  background-color: #c1c1c1;
}

.testresult-indicator.checkedin {
  background-color: #565656;
}

.testresult-indicator.negative {
  background-color: #008000;
}

.testresult-indicator.positive {
  background-color: #C44B4F;
}

.testresult-indicator.invalid {
  background-color: #e9cd0e;
}

.container-h-legend {
  display: flex;
  justify-content: space-between;
}

.table-legend .items > div > div {
  display: flex;
  align-items: center;
}

.table-legend .items .testresult-indicator {
  width: 12px;
  height: 12px;
}

.table-legend b {
  display: block;
  margin-bottom: 5px;
}

.table-legend .items > div {
  margin-right: 15px;
}

.table-legend .items > div > div > *:first-child {
  margin-right: 10px;
  width: 13px;
}

.table-legend .items > div > div > div {
  font-size: 11px;
  margin-bottom: 3px;
}

.table-legend .items {
  display: flex;
}

.title-desc-container {
  min-height: 90px;
}

input.ng-touched.ng-invalid {
  border-color: #C44B4F;
}

.btn-container {
  width: 100%;
  margin: auto;
  padding-right: 12px;
  padding-bottom: 12px;
  display: flex;
}

.btn-container > * {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.input-btn-wrap input {
  width: 190px;
  margin-right: 0;
  border-right: 0;
  border-radius: 3px 0 0 3px !important;
}

.input-btn-wrap button {
  display: flex;
  align-items: center;
  border-radius: 0 3px 3px 0 !important;
}

.test-list-item {
  height: 60px !important;
}

.icon-only-btn {
  width: 40px !important;
  justify-content: center;
  padding: 20px 0 !important;
  & > fa-icon {
    margin-right: 0 !important;
  }
}

virtual-scroller {
  width: 100%;
  height: 100%;
}

.v-scroll-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;

  .v-scroll-middle {
    flex-grow: 1;
  }

  .v-scroll-bottom {
    margin-top: auto;
  }
}

@media (max-width: 1024px) {
  html, body {
    font-size: 14px;
  }
}
